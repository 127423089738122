import React, { useState, useEffect, useCallback, useRef } from 'react'
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import { TravelAllowanceRequestToShow } from '../../types/fields';
import performApiRequest from '../../utils/performApiRequest';
import Select, { components } from 'react-select';
import showExactDate from '../../utils/showExactDate';
import formatNumber from '../../utils/formatNumber';

interface ShowAllTravelAllowanceRequestsProps {
  endpointURL: string;
}

interface OptionType {
  label: string;
  value: string;
}

const requestStatusOptions: OptionType[] = [
  { value: 'Enviada', label: 'Enviada' },
  { value: 'Recibida', label: 'Recibida' },
  { value: 'En proceso de revisión', label: 'En proceso de revisión' },
  { value: 'Pendiente de validación por Contabilidad', label: 'Pendiente de validación por Contabilidad' },
  { value: 'Validada por Contabilidad', label: 'Validada por Contabilidad' },
  { value: 'Aprobada - Pagada por Tesorería', label: 'Aprobada - Pagada por Tesorería' },
];

const viaticTypeOptions: OptionType[] = [
  { value: 'Nacional - Región Metropolitana', label: 'Nacional - Región Metropolitana' },
  { value: 'Nacional - Otras regiones', label: 'Nacional - Otras regiones' },
  { value: 'Internacional', label: 'Internacional' },
];

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      {props.isSelected ? <span>✔️ </span> : null}
      {props.label}
    </components.Option>
  );
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    maxHeight: '10%',
    overflow: 'hidden'
  }),
  multiValue: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: 'nowrap',
    overflow: 'auto'
  }),
};

export const ShowAllTravelAllowanceRequests: React.FC<ShowAllTravelAllowanceRequestsProps> = ({ endpointURL }) => {
    const isAuthenticated = useAuthStatus();
    const [travelAllowanceRequests, setTravelAllowanceRequests] = useState<TravelAllowanceRequestToShow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rut, setRut] = useState('');
    const [lastName, setLastName] = useState('');
    const [viaticType, setViaticType] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [selectedRequestStatus, setSelectedRequestStatus] = useState<string[]>([]);
    
    const selectedRequestStatusOptions = requestStatusOptions.filter(option =>
      selectedRequestStatus.includes(option.value)
    );

    const selectedViaticTypeOptions = viaticTypeOptions.filter(option => 
      viaticType.includes(option.value)
    );

    const fetchTravelAllowanceRequests = useCallback(async () => {
      setIsLoading(true);
      try {
          const queryParams = new URLSearchParams({
              rut,
              lastName,
              page: currentPage.toString(),
              limit: '10',
          });

          selectedRequestStatus.forEach(status => {
            queryParams.append('requestStatus', status);
          });

          viaticType.forEach(status => {
            queryParams.append('viaticType', status);
          });

          const endpoint = `${endpointURL}?${queryParams.toString()}`;
          const requestOptions = { 
            method: 'GET',
            'Content-Type': 'application/json',
          };
          if (isAuthenticated) {
            const response = await performApiRequest(endpoint, requestOptions);
            setTravelAllowanceRequests(response.data.requests);
            setTotalPages(response.data.totalPages || 1);
          }
      } catch (error) {
          console.error('There was an error fetching the Travel Allowance requests:', error);
      } finally {
          setIsLoading(false);
      }
  }, [rut, lastName, viaticType, selectedRequestStatus, currentPage, isAuthenticated, endpointURL]);

  const fetchTravelAllowanceRequestsRef = useRef(fetchTravelAllowanceRequests);

  useEffect(() => {
    fetchTravelAllowanceRequestsRef.current = fetchTravelAllowanceRequests;
  }, [fetchTravelAllowanceRequests]);

  useEffect(() => {
      if (isAuthenticated) {
          fetchTravelAllowanceRequestsRef.current();
      }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchTravelAllowanceRequestsRef.current();
  }, [currentPage]);

    const handleClick = (request_id: number) => {
        navigate(`/particularTravelAllowanceRequest/${request_id}`);
    }

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    const handlePageChange = (newPage: number) => {
      setCurrentPage(newPage);
      //fetchTravelAllowanceRequests();
  };
  

    return (
        <div className="grid grid-cols-1 justify-center items-center p-4 mb-10">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
            <input
              type="text"
              placeholder="RUT del funcionario..."
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              className="rounded-lg px-2 py-1"
            />
            <input
              type="text"
              placeholder="Apellido del funcionario..."
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="rounded-lg px-2 py-1"
            />
            <Select
              isMulti
              options={viaticTypeOptions}
              styles={customStyles}
              components={{ Option: CustomOption }}
              classNamePrefix="select"
              placeholder="Tipo de viático"
              className='text-sm font-semibold'
              value={selectedViaticTypeOptions}
              onChange={(selectedOptions) => {
                const values = selectedOptions.map(option => option.value);
                setViaticType(values);
              }}
            />
            <Select
              isMulti
              options={requestStatusOptions}
              styles={customStyles}
              components={{ Option: CustomOption }}
              classNamePrefix="select"
              placeholder="Estado de la solicitud"
              className='text-sm font-semibold'
              value={selectedRequestStatusOptions}
              onChange={(selectedOptions) => {
                const values = selectedOptions.map(option => option.value);
                setSelectedRequestStatus(values);
              }}
            />
            <button 
              onClick={() => fetchTravelAllowanceRequests()}
              className="rounded-lg bg-blue-600 hover:bg-blue-800 px-2 py-1 text-white ml-5">
                Buscar
            </button>
          </div>
          <div className="overflow-x-auto rounded mb-4">
            <h2 className="py-3 text-gray-700 text-md font-semibold mb-10">Selecciona el número de una solicitud para revisarla en detalle: </h2> 
            <table className="min-w-full bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <thead className="bg-sectionBarBackgroundColor">
                <tr>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">No. de solicitud</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Solicitante</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Nombre del funcionario</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">RUT del funcionario</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Tipo de viático</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Monto</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Fecha del viaje</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Etapa</th>
                </tr>
              </thead>
              {(travelAllowanceRequests.length > 0) ? (
                <tbody className="text-gray-700">
                  {travelAllowanceRequests.map((request: TravelAllowanceRequestToShow) => (
                    <tr key={request.id} className="border-b">
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">
                        <button className="text-customBlue hover:text-blue-800" onClick={() => handleClick(request.id)}>
                          {`${request.id} - ${request.userFaculty}`}
                        </button>
                      </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{request.formCompleterUserData.names} {request.formCompleterUserData.ap_pat} {request.formCompleterUserData.ap_mat}</td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{request.requestingUserData.names} {request.requestingUserData.ap_pat} {request.requestingUserData.ap_mat}</td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{request.requestingUserData.rut}-{request.requestingUserData.dv_rut} </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{request.viatic_type} </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{formatNumber(request.clp_value)} CLP </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{showExactDate(request.travel_start_date)} - {showExactDate(request.travel_end_date)} </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{request.high_order_status}</td>
                    </tr>
                  ))}
                  </tbody>
                ) :
                ( null )
              }
            </table>
            {travelAllowanceRequests.length === 0 && (
              <div className="text-center grey-800 font-semibold font-custom"> No se encontraron solicitudes de viático.</div>
            )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-5 items-center">
          <button
            className={`cursor-pointer px-2 py-1 bg-blue-400 text-white rounded-md ${currentPage === 1 ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === 1 ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Anterior
          </button>
          {/* This div remains centered as other elements take up space even when invisible */}
          <div className="px-1 py-1 rounded-md text-center font-semibold text-center col-start-2 md:col-start-3 col-span-3 md:col-span-1">Página {currentPage} de {totalPages}</div>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === totalPages ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Siguiente
          </button>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === totalPages ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </div>
    </div>
  )
}
